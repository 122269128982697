import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from 'vue-slide-toggle';
import mainButton from '@/components/main-button/index.vue'
import mainSelect from '@/components/main-select/index.vue'
import createLegal from "@/components/modal-windows/create-legal/index.vue";
import info from '@/modules/profile/section/user-info/index.vue';
import bonuses from '@/modules/profile/section/bonuses/index.vue';
import orders from '@/modules/profile/section/orders/index.vue';
import historyOrders from '@/modules/profile/section/history/index.vue';
import accounts from '@/modules/profile/section/accounts/index.vue';
import catalog from '@/modules/profile/section/catalog/index.vue';
import calculator from '@/modules/profile/section/calculator/index.vue';
import constructor from '@/modules/profile/section/constructor/index.vue';

//sections


export default {
    name: "profile",
    components: {
        mainButton,
        mainSelect,
        createLegal,
        bonuses,
        accounts,
        orders,
        historyOrders,
        info,
        catalog,
        calculator,
        constructor,
        VueSlideToggle
    },
    data() {
        return {
            validationErrors: {},
            f: false,
            legal: true,
            sidebar: {
                info: false,
                bonuses: false,
                orders: false,
                history: false,
                accounts: false,
                catalog: false,
                calculator: false,
                constructor: false,
                snowLoad: false,
            },
            addLegal: false,
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: ''},
                {src: 'profile', title: this.$t('user.personalOffice'), slug: 'me'},
                {src: 'profile', title: this.$t('personalOffice.personalInfo.title'), slug: 'me'}
            ],
        }
    },
    created() {
        this.fetchUserInfo()
            .then()
            .catch((e) => {
                if (e.status === 401) this.$router.push({name: 'home'})
            })


        this.getCategories().then(() => {
            this.selectedCalculator = this.snowLoadCategories[0]
            this.getCurrentCalculator({slug: this.selectedCalculator.slug}).then(() => {
                this.renderCalculator(this.currentSnowLoadCalculator.lengths)
                this.widthKey += 1
                this.lengthKey += 2
            }).catch(() => {
                console.log()
            })
        }).catch(() => {
            console.log()
        })

        this.setRequestFlag(true);
    },
    watch: {
        'selectedCalculator': function () {
            this.getCurrentCalculator({slug: this.selectedCalculator.slug}).then(() => {
                this.renderCalculator(this.currentSnowLoadCalculator.lengths)

            }).catch(() => {
                console.log()
            })
        }
    },
    computed: {
        ...mapGetters({
            profileRequestsFlag: 'profile/isLoadingProfile',
            userInfoPayload: 'profile/userInfo',
            userNameForHeader: 'profile/userFullName',
            globalRequestsFlag: 'system/globalRequestsFlag',
            profileLoaderStatus: 'profile/profileLoaderStatus',
            snowLoadCategories: 'constructors/snowLoadCategories',
        }),
        routeWatcher() {
            this.crumbs[2].src = `/profile/${this.$route.name}`
            this.crumbs[2].slug = this.$route.name
            switch (this.$route.name) {
                case 'user-info': {
                    document.title = `${this.$t('personalOffice.personalInfo.title')}`
                    this.crumbs[2].title = this.$t('personalOffice.personalInfo.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'me'
                    break
                }
                case 'bonuses': {
                    document.title = `${this.$t('personalOffice.bonusesAndSales.title')}`
                    this.crumbs[2].title = this.$t('personalOffice.bonusesAndSales.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'bonuses'
                    break
                }
                case 'orders': {
                    document.title = `${this.$t('personalOffice.orders.currentOrders')}`
                    this.crumbs[2].title = this.$t('personalOffice.orders.currentOrders')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'orders'
                    break
                }
                case 'history': {
                    document.title = `${this.$t('personalOffice.orders.ordersHistory')}`
                    this.crumbs[2].title = this.$t('personalOffice.orders.ordersHistory')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'history'
                    break
                }
                case 'catalog': {
                    document.title = `${this.$t('personalOffice.productsCatalog.title')}`
                    this.crumbs[2].title = this.$t('personalOffice.productsCatalog.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'catalog'
                    break
                }
                case 'arched_canopy': {
                    document.title = `${this.$t('roofingCalculator.title')}`
                    this.crumbs[2].title = this.$t('roofingCalculator.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'arched_canopy'
                    break
                }
                case 'accounts': {
                    document.title = `${this.$t('personalOffice.mutualAccounts.title')}`
                    this.crumbs[2].title = this.$t('personalOffice.mutualAccounts.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'accounts'
                    break
                }
                case 'profile-constructor': {
                    document.title = `${this.$t('roofingCalewculator.title')}`
                    this.crumbs[2].title = this.$t('roofingCalculator.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'profile-constructor'
                    break
                }
                case 'profile-calculator': {
                    document.title = `${this.$t('roofingCalculator.title')}`
                    this.crumbs[2].title = this.$t('roofingCalculator.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'profile-calculator'
                    break
                }
                case 'profile-snow-load': {
                    document.title = `${this.$t('productCard.snowLoadCalculation.title')}`
                    this.crumbs[2].title = this.$t('productCard.snowLoadCalculation.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'profile-snow-load'
                    break
                }
                case 'civil': {
                    document.title = `${this.$t('personalOffice.ideasDesigner.title')}`
                    this.crumbs[2].title = this.$t('personalOffice.ideasDesigner.title')
                    this.crumbs[2].src = 'profile'
                    this.crumbs[2].slug = 'civil'
                    break
                }
            }
            return this.crumbs
        }
    },

    methods: {
        addLegalUser() {
            this.addLegal = !this.addLegal;
        },
        ...mapMutations({
            resetBasket: 'basket/RESET_BASKET',
            closeSuccessPopup: 'order/SHOW_SUCCESS_POPUP',
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        ...mapActions({
            fetchUserInfo: 'profile/GET_USER_DATA',
            logout: 'auth/LOGOUT',
            getCategories: 'constructors/GET_SNOW_LOAD_CATEGORIES',

            //mocked data for snow load
            getCurrentProduct: 'catalog/GET_PRODUCT',
        }),
        toggleCardState(item) {
            this.crumbs[2].src = `/profile/${this.$route.name}`
            this.crumbs[2].slug = this.$route.name
            switch (item) {
                case 'info': {
                    this.crumbs[2].title = this.$t('personalOffice.personalInfo.title')
                    this.sidebar.info = !this.sidebar.info
                    break
                }
                case 'bonuses': {
                    this.crumbs[2].title = this.$t('personalOffice.bonusesAndSales.title')
                    this.sidebar.bonuses = !this.sidebar.bonuses
                    break
                }
                case 'orders': {
                    this.crumbs[2].title = this.$t('personalOffice.orders.currentOrders')
                    this.sidebar.orders = !this.sidebar.orders
                    break
                }
                case 'history': {
                    this.crumbs[2].title = this.$t('personalOffice.orders.ordersHistory')
                    this.sidebar.history = !this.sidebar.history
                    break
                }
                case 'accounts': {
                    this.sidebar.accounts = !this.sidebar.accounts
                    break
                }
                case 'catalog': {
                    this.crumbs[2].title = this.$t('personalOffice.productsCatalog.title')
                    this.sidebar.catalog = !this.sidebar.catalog
                    break
                }
                case 'calculator': {
                    this.crumbs[2].title = this.$t('roofingCalculator.title')
                    this.sidebar.calculator = !this.sidebar.calculator
                    this.sidebar.constructor = false
                    this.sidebar.snowLoad = false
                    break
                }
                case 'snow-load': {
                    this.crumbs[2].title = this.$t('personalOffice.loadCalculation.title')
                    this.sidebar.snowLoad = !this.sidebar.snowLoad
                    this.sidebar.constructor = false
                    this.sidebar.calculator = false
                    break
                }
                case 'constructor': {
                    this.crumbs[2].title = this.$t('personalOffice.ideasDesigner.title')
                    this.sidebar.constructor = !this.sidebar.constructor
                    this.sidebar.calculator = false
                    this.sidebar.snowLoad = false
                    break
                }
            }
        },
        logoutHandler() {
            this.logout().then(() => {
                localStorage.removeItem('user_token')
            })
        }
    }
}
